import React from 'react';
import Modal from "../Semantic/Modal";
import Button from "../Semantic/Button";
import DailyStats from "./DailyStats";
var StatsModal = function (props) {
    return (React.createElement(Modal, { trigger: React.createElement(Button, { icon: "chart bar" }), size: "mini", closeIcon: true },
        React.createElement(Modal.Content, null,
            React.createElement(DailyStats, null))));
};
export default StatsModal;
