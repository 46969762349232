import React from 'react';
import styles from '../../styles/ResultGrid.module.scss';
import Row from "./Row";
import Headers from "./Headers";
var ROWS = [0, 1, 2, 3, 4, 5];
var ResultGrid = function (_a) {
    var answerCard = _a.answerCard, guesses = _a.guesses;
    return (React.createElement("div", { className: styles.tableContainer },
        React.createElement(Headers, null),
        ROWS.map(function (row) { return (React.createElement(Row, { guesses: guesses, answerCard: answerCard, row: row, key: row })); })));
};
export default ResultGrid;
