var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useMemo, useState } from "react";
import { useCookies } from "react-cookie";
import { useLocalStorage } from "react-use";
import RequestService from "../services/requestService.service";
import { setDarkModeCss } from "../services/theme.service";
import { CookieKeys } from "../constants/cookies";
import CardAutocomplete from "./CardAutocomplete";
import CardModal from "./CardModal";
import ResultGrid from "./ResultGrid/ResultGrid";
import AnswerReveal from "./AnswerReveal";
import CardList from "./CardList/CardList";
import NavBar from "./NavBar";
import GameStatus from "./GameStatus/GameStatus";
import WinModal from "./WinModal";
import styles from "../styles/PageWrapper.module.scss";
var PageWrapper = function (props) {
    var _a = useState(), cardCandidate = _a[0], setCardCandidate = _a[1];
    var _b = useState([]), guesses = _b[0], setGuesses = _b[1];
    var _c = useState(), answerCard = _c[0], setAnswerCard = _c[1];
    var _d = useState(0), codexNumber = _d[0], setCodexNumber = _d[1]; // The incremented id of today's answer
    var _e = useState([]), topCommanders = _e[0], setTopCommanders = _e[1];
    var _f = useState({}), cardMap = _f[0], setCardMap = _f[1];
    var cookies = useCookies([CookieKeys.isDaily])[0];
    var isDaily = cookies[CookieKeys.isDaily] !== 'false';
    var darkModeEnabled = cookies[CookieKeys.darkMode] === 'true';
    var _g = useLocalStorage('storedGuesses', []), storedGuesses = _g[0], setStoredGuesses = _g[1];
    var _h = useLocalStorage('storedAnswerCard'), storedAnswerCard = _h[0], setStoredAnswerCard = _h[1];
    var _j = useLocalStorage('storedWinMap', {}), storedWinMap = _j[0], setStoredWinMap = _j[1];
    var isWon = useMemo(function () {
        return !!(guesses.length && answerCard && guesses[guesses.length - 1].id === answerCard.id);
    }, [guesses, answerCard]);
    var isLost = useMemo(function () {
        return guesses.length === 6 && !isWon;
    }, [guesses, isWon]);
    var fetchRandomLegend = function (topList) {
        if (!topList.length)
            return;
        var randomCommander = topList[Math.floor(Math.random() * topList.length)];
        RequestService.get("https://api.scryfall.com/cards/search?order=released&unique=prints&dir=asc&q=!\"".concat(randomCommander, "\" not:reprint")).then(function (data) {
            setAnswerCard(data.data[0]);
        });
    };
    useEffect(function () {
        if (darkModeEnabled)
            setDarkModeCss();
    }, []);
    useEffect(function () {
        if (!topCommanders.length) {
            RequestService.get("https://storage.googleapis.com/legend-tutor.appspot.com/daily.json").then(function (data) {
                setTopCommanders(Object.keys(data.cards));
                setCardMap(data.cards);
                if (isDaily) {
                    if ((storedAnswerCard === null || storedAnswerCard === void 0 ? void 0 : storedAnswerCard.id) === data.answer.id && storedGuesses)
                        setGuesses(storedGuesses);
                    else
                        setStoredGuesses([]);
                    setAnswerCard(data.answer);
                    setStoredAnswerCard(data.answer);
                    setCodexNumber(data.number);
                }
                else
                    fetchRandomLegend(Object.keys(data.cards));
            });
        }
    }, [isDaily, storedGuesses, topCommanders]);
    useEffect(function () {
        var _a;
        if ((isWon || isLost) && codexNumber && !(storedWinMap === null || storedWinMap === void 0 ? void 0 : storedWinMap.hasOwnProperty(codexNumber)))
            setStoredWinMap(__assign(__assign({}, storedWinMap), (_a = {}, _a["".concat(codexNumber)] = (isWon ? guesses.length : 0), _a)));
    }, [isWon, isLost, storedWinMap, codexNumber, setStoredWinMap, isDaily]);
    var guessCard = function () {
        if (!cardCandidate)
            return;
        var newGuesses = guesses.concat([cardCandidate]);
        setGuesses(newGuesses);
        if (isDaily)
            setStoredGuesses(newGuesses);
        setCardCandidate(undefined);
    };
    return (React.createElement("div", { className: styles.pageContainer },
        React.createElement(NavBar, null),
        React.createElement("div", { className: styles.controls },
            React.createElement(CardAutocomplete, { disabled: isWon, onSelect: setCardCandidate, commanderList: topCommanders }),
            React.createElement(CardList, { cardMap: cardMap })),
        React.createElement(GameStatus, null),
        React.createElement(ResultGrid, { answerCard: answerCard, guesses: guesses }),
        React.createElement("div", { className: styles.answers },
            !isDaily && !isWon && !isLost && React.createElement(AnswerReveal, { answerCard: answerCard }),
            React.createElement(WinModal, { isWon: isWon, isLost: isLost, answer: answerCard, codexNumber: codexNumber, guesses: guesses })),
        React.createElement(CardModal, { clearCard: function () { return setCardCandidate(undefined); }, guessCard: guessCard, card: cardCandidate })));
};
export default PageWrapper;
