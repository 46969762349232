// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".v1xn_Y5HA3JECo2Tzm13{display:flex;width:500px;justify-content:flex-end;padding:1rem}@media(max-width: 500px){.v1xn_Y5HA3JECo2Tzm13{width:100%}}.prlzGiYaQTVHgsJkFdz_{display:flex !important;flex-direction:column;gap:1rem}", "",{"version":3,"sources":["webpack://./src/styles/SettingsModal.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CAEA,WAAA,CAEA,wBAAA,CAEA,YAAA,CAEA,yBATF,sBAUI,UAAA,CAAA,CAIJ,sBACE,uBAAA,CACA,qBAAA,CAEA,QAAA","sourcesContent":[".content {\n  display: flex;\n\n  width: 500px;\n\n  justify-content: flex-end;\n\n  padding: 1rem;\n\n  @media (max-width: 500px) {\n    width: 100%;\n  }\n}\n\n.modalContent {\n  display: flex !important;\n  flex-direction: column;\n\n  gap: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "v1xn_Y5HA3JECo2Tzm13",
	"modalContent": "prlzGiYaQTVHgsJkFdz_"
};
export default ___CSS_LOADER_EXPORT___;
