import React from "react";
import Modal from "../Semantic/Modal";
import styles from "../../styles/Card.module.scss";
export var CardModal = function (_a) {
    var card = _a.card, _b = _a.size, size = _b === void 0 ? 300 : _b, trigger = _a.trigger;
    return (React.createElement(Modal, { basic: true, size: "mini", trigger: trigger },
        React.createElement("div", { className: styles.modal },
            React.createElement(Card, { card: card, size: size }))));
};
export var SimpleCard = function (_a) {
    var uid = _a.uid, size = _a.size;
    var imgUrl = "https://c1.scryfall.com/file/scryfall-cards/normal/front/".concat(uid[0], "/").concat(uid[1], "/").concat(uid, ".jpg");
    return React.createElement(ImageCard, { url: imgUrl, size: size });
};
export var Card = function (_a) {
    var _b;
    var card = _a.card, size = _a.size;
    var imgUrl = card.card_faces
        ? card.card_faces[0].image_uris.normal
        : (_b = card.image_uris) === null || _b === void 0 ? void 0 : _b.normal;
    if (!imgUrl)
        return null; // interesting case for the scryfall id
    return React.createElement(ImageCard, { url: imgUrl, size: size });
};
var ImageCard = function (_a) {
    var url = _a.url, _b = _a.size, size = _b === void 0 ? 220 : _b;
    return React.createElement("img", { width: size, className: styles.card, src: url });
};
