import React from "react";
import { useCookies } from "react-cookie";
import { CookieKeys, Expires } from "../constants/cookies";
import InstructionsModal from "./InstructionsModal";
import Button from "./Semantic/Button";
import SettingsModal from "./SettingsModal";
import styles from "../styles/NavBar.module.scss";
import ArchidektLogo from "./ArchidektLogo";
import StatsModal from "./DailyStats/StatsModal";
import { ReactComponent as Logo } from "../assets/logo.svg";
import { logAppEvent } from "../services/firebase.service";
var NavBar = function () {
    var _a = useCookies([CookieKeys.isDaily]), _cookies = _a[0], setCookies = _a[1];
    var handleRefresh = function () {
        logAppEvent("top_refresh_button");
        setCookies(CookieKeys.isDaily, "false", { maxAge: Expires.HOUR });
        window.location.reload();
    };
    return (React.createElement("div", { className: styles.navBarOuter },
        React.createElement("div", { className: styles.navBarContainer },
            React.createElement("a", { href: "/", className: styles.logoContainer },
                React.createElement(Logo, null)),
            React.createElement("div", { className: styles.buttons },
                React.createElement(InstructionsModal, null),
                React.createElement(StatsModal, null),
                React.createElement(Button, { icon: "shuffle", title: "Infinite Mode", onClick: handleRefresh }),
                React.createElement(SettingsModal, null))),
        React.createElement("div", { className: styles.subtitle },
            React.createElement("div", null, "Presented by\u00A0"),
            " ",
            React.createElement(ArchidektLogo, null))));
};
export default NavBar;
