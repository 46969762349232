import React, { useState } from "react";
import RequestService from "../services/requestService.service";
import Search from "./Semantic/Search";
var CardAutocomplete = function (_a) {
    var onSelect = _a.onSelect, commanderList = _a.commanderList, disabled = _a.disabled;
    var _b = useState(""), searchValue = _b[0], setSearchValue = _b[1];
    var _c = useState(false), loading = _c[0], setLoading = _c[1];
    // This should ideally be done with useRef but the ref semantic provides doesn't have blur() for some reason so we
    // set the element when the search is changed.
    var _d = useState(), searchElement = _d[0], setSearchElement = _d[1];
    var handleSelect = function (_e, data) {
        setLoading(true);
        return RequestService.get("https://api.scryfall.com/cards/search?order=released&unique=prints&dir=asc&q=!\"".concat(data.result.title, "\" not:reprint")).then(function (data) {
            searchElement === null || searchElement === void 0 ? void 0 : searchElement.blur();
            onSelect(data.data[0]);
            setLoading(false);
            setSearchValue("");
        });
    };
    var handleSearchChange = function (event, value) {
        setSearchValue(value.value);
        if (!searchElement)
            setSearchElement(event.target);
    };
    return (React.createElement("div", null,
        React.createElement(Search, { disabled: disabled, loading: loading, value: searchValue, minCharacters: 2, placeholder: "Guess a card", noResultsMessage: "There are no cards with that name in the top 500 commanders", onSearchChange: handleSearchChange, results: commanderList
                .filter(function (name) {
                return name.toLowerCase().includes(searchValue.toLowerCase());
            })
                .slice(0, 15)
                .map(function (name) { return ({ title: name }); }), onResultSelect: handleSelect })));
};
export default CardAutocomplete;
