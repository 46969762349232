// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cpOFyoGcFa5Gr_8ZWw9q{padding-top:2px}.cpOFyoGcFa5Gr_8ZWw9q text{fill:var(--color) !important;color:var(--color) !important;font-style:normal}", "",{"version":3,"sources":["webpack://./src/styles/ArchidektLogo.module.scss"],"names":[],"mappings":"AAEA,sBACE,eAAA,CACA,2BACE,4BAAA,CACA,6BAAA,CACA,iBAAA","sourcesContent":["@import \"variables\";\n\n.logoContainer {\n  padding-top: 2px;\n  text {\n    fill: $color !important;\n    color: $color !important;\n    font-style: normal;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logoContainer": "cpOFyoGcFa5Gr_8ZWw9q"
};
export default ___CSS_LOADER_EXPORT___;
