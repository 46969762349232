import React, { useState } from "react";
import { useInterval } from "react-use";
var Countdown = function (_a) {
    var className = _a.className;
    var getCountdown = function () {
        var now = new Date();
        var updateTime = new Date(+now);
        // Time until 5am UTC
        var timeRemaining = 60 * 60 * 24 * 1000 -
            (now.getTime() - updateTime.setUTCHours(8, 0, 0, 0));
        var time = new Date(timeRemaining).toISOString().slice(11, 19); // HH:MM:SS
        return time;
    };
    var _b = useState(getCountdown()), countdown = _b[0], setCountdown = _b[1];
    useInterval(function () {
        var updatedTime = getCountdown();
        setCountdown(updatedTime);
    }, 1000);
    return (React.createElement("div", { className: className },
        "The next daily puzzle will be available in: ",
        React.createElement("h4", null, countdown)));
};
export default Countdown;
