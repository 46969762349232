import React from "react";
import { useCookies } from "react-cookie";
import { CookieKeys } from "../../constants/cookies";
import styles from "../../styles/GameStatus.module.scss";
import { Icon } from "semantic-ui-react";
var GameStatus = function () {
    var _a = useCookies([CookieKeys.isDaily]), cookies = _a[0], _setCookies = _a[1], deleteCookie = _a[2];
    var isDaily = cookies[CookieKeys.isDaily] !== "false";
    var handleGoBackToDaily = function () {
        deleteCookie(CookieKeys.isDaily);
        window.location.reload();
    };
    // If you're working on the daily
    // If you're not working on the daily
    // Link back to the daily if you're not on it.
    // When the game plans to update
    if (isDaily)
        return React.createElement("p", { className: styles.message },
            "You are guessing the Commander of the Day in ",
            React.createElement(Icon, { name: "calendar alternate outline" }),
            "Daily Mode.");
    return (React.createElement("div", { className: styles.message },
        "This is a random Commander in ",
        React.createElement(Icon, { name: "shuffle" }),
        "Infinite Mode. If you\u2019d like to return to ",
        React.createElement(Icon, { name: "calendar alternate outline" }),
        "Daily Mode,",
        React.createElement("button", { className: styles.goBack, onClick: handleGoBackToDaily }, "click here")));
};
export default GameStatus;
