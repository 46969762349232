import React from "react";
import { CardModal } from "../CardList/Card";
import styles from "../../styles/GuessCell.module.scss";
var GuessCell = function (_a) {
    var _b;
    var guessCard = _a.guessCard;
    var cardUrl = guessCard.card_faces
        ? guessCard.card_faces[0].image_uris.art_crop
        : (_b = guessCard.image_uris) === null || _b === void 0 ? void 0 : _b.art_crop;
    var trigger = (React.createElement("button", { className: styles.guessCell },
        React.createElement("img", { alt: guessCard.name, src: cardUrl, className: styles.image })));
    return React.createElement(CardModal, { card: guessCard, trigger: trigger });
};
export default GuessCell;
