import React, { useEffect, useState } from "react";
import { Icon } from "semantic-ui-react";
import { useCookies } from "react-cookie";
import { CookieKeys, Expires } from "../constants/cookies";
import Modal from "./Semantic/Modal";
import { Card } from "./CardList/Card";
import Countdown from "./GameStatus/Countdown";
import Button from "./Semantic/Button";
import styles from "../styles/WinModal.module.scss";
import { gameStateToEmoji } from "../services/gameLogic.service";
import DailyStats from "./DailyStats/DailyStats";
import { logAppEvent } from "../services/firebase.service";
var iosPlatforms = ['iPhone', 'iPad', 'iPod'];
var WinModal = function (_a) {
    var isWon = _a.isWon, isLost = _a.isLost, answer = _a.answer, guesses = _a.guesses, codexNumber = _a.codexNumber;
    var isShareable = !!navigator.share && (iosPlatforms.includes(navigator.platform) || /Android/.test(navigator.userAgent)); // Only share on android or ios
    var _b = useState(false), open = _b[0], setOpen = _b[1];
    var _c = useState(false), answerAvailable = _c[0], setAnswerAvailable = _c[1];
    var _d = useCookies([CookieKeys.isDaily]), cookies = _d[0], setCookies = _d[1];
    var isDaily = cookies[CookieKeys.isDaily] !== "false";
    var handleRefresh = function () {
        logAppEvent('win_modal_infinite_button');
        setCookies(CookieKeys.isDaily, "false", { maxAge: Expires.HOUR });
        window.location.reload();
    };
    var handleShare = function () {
        if (isShareable) {
            logAppEvent('win_modal_share');
            navigator.share({ title: 'Commander Codex', text: shareText });
        }
        else {
            logAppEvent('win_modal_copy');
            navigator.clipboard.writeText(shareText);
        }
    };
    useEffect(function () {
        // The open is delayed to allow animation finish
        if (isWon || isLost)
            setTimeout(function () {
                setOpen(true);
                setAnswerAvailable(true);
            }, 2500);
    }, [isWon, isLost]);
    var emojiGameResults = answer ? gameStateToEmoji(answer, guesses) : "";
    var shareText = "Commander Codex ".concat(codexNumber, " ").concat(guesses.length, "/6\n").concat(emojiGameResults, "\n");
    return (React.createElement(React.Fragment, null,
        answerAvailable && React.createElement(Button, { onClick: function () { return setOpen(true); } }, "View Answer"),
        React.createElement(Modal, { open: open, size: "mini", onClose: function () { return setOpen(false); }, closeIcon: true },
            React.createElement(Modal.Header, null, isWon ? 'Congratulations!' : 'So close!'),
            React.createElement(Modal.Content, null,
                React.createElement("div", { className: styles.winContent },
                    isLost && React.createElement("p", null, "Sorry, that's all the guesses you get. You'll get it next time!"),
                    isDaily && isWon && React.createElement("p", null, "You've guessed the Commander of the Day!"),
                    !isDaily && isWon && React.createElement("p", null, "You've guessed another card in infinite mode!"),
                    React.createElement(Countdown, { className: styles.spacer }),
                    answer && React.createElement(Card, { card: answer }))),
            React.createElement(Modal.Actions, null,
                isDaily && React.createElement("div", { className: styles.shareables },
                    React.createElement("a", { href: "https://twitter.com/intent/tweet?text=".concat(encodeURI(shareText), "&url=https://commandercodex.com/"), target: "_blank", rel: "noreferrer", "data-size": "large" },
                        React.createElement(Button, { onClick: function () { return logAppEvent('win_modal_twitter'); }, icon: true, labelPosition: "left", fluid: true, color: "twitter" },
                            React.createElement(Icon, { name: "twitter" }),
                            " Twitter")),
                    React.createElement(Button, { icon: true, labelPosition: "left", fluid: true, onClick: handleShare },
                        React.createElement(Icon, { name: isShareable ? "share" : "clipboard" }),
                        " ",
                        isShareable ? "Share" : "Copy to Clipboard")),
                React.createElement("a", { href: "https://archidekt.com/decks/sandbox?commanderAs=".concat(answer === null || answer === void 0 ? void 0 : answer.name), target: "_blank", rel: "noreferrer" },
                    React.createElement(Button, { onClick: function () { return logAppEvent('win_modal_export'); }, icon: true, labelPosition: "left", className: styles.infinitButton },
                        React.createElement(Icon, { name: "share" }),
                        "Create on Archidekt")),
                React.createElement(Button, { icon: true, labelPosition: "left", onClick: handleRefresh, className: styles.infinitButton },
                    React.createElement(Icon, { name: "shuffle" }),
                    " Keep going in Infinite Mode"),
                isDaily && React.createElement(DailyStats, null)))));
};
export default WinModal;
