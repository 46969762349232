import React from 'react';
import { ReactComponent as Logo } from '../assets/archidekt_logo.svg';
// import {ReactComponent as LogoText} from '../assets/logo_text.svg'
import styles from '../styles/ArchidektLogo.module.scss';
var ArchidektLogo = function () {
    return (React.createElement("div", { className: styles.logoContainer },
        React.createElement("a", { href: "https://archidekt.com", target: "_blank", rel: "noopener noreferrer" },
            React.createElement(Logo, { height: 15 }))));
};
export default ArchidektLogo;
