import React from 'react';
import styles from "../../styles/ResultGrid.module.scss";
import GuessCell from "./GuessCell";
import Cell from "./Cell";
var COLS = [0, 1, 2, 3, 4];
var Row = function (_a) {
    var guesses = _a.guesses, row = _a.row, answerCard = _a.answerCard;
    return (React.createElement("div", { className: styles.rowContainer },
        guesses.length > row && React.createElement(GuessCell, { guessCard: guesses[row] }),
        COLS.map(function (col) { return (React.createElement(Cell, { col: col, guessCard: guesses.length > row ? guesses[row] : undefined, answerCard: answerCard, key: col })); })));
};
export default Row;
