// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DSdtRngiFy3ASoYzXxw9{background:transparent;border:none;color:#fa890d;text-decoration:underline}.DSdtRngiFy3ASoYzXxw9:hover:enabled{cursor:pointer}.WvdxOWhy7hRKOrDNNbOt{text-align:center;padding:5px}", "",{"version":3,"sources":["webpack://./src/styles/GameStatus.module.scss","webpack://./src/styles/variables.scss"],"names":[],"mappings":"AAEA,sBACE,sBAAA,CACA,WAAA,CAEA,aCNiB,CDOjB,yBAAA,CAEA,oCACE,cAAA,CAIJ,sBACE,iBAAA,CACA,WAAA","sourcesContent":["@import \"variables\";\n\n.goBack {\n  background: transparent;\n  border: none;\n\n  color: $archidekt-orange;\n  text-decoration: underline;\n\n  &:hover:enabled {\n    cursor: pointer;\n  }\n}\n\n.message {\n  text-align: center;\n  padding: 5px;\n}","$archidekt-orange: #fa890d;\n\n$background-color: var(--background-color);\n$rgb-background: var(--rgb-background);\n$light-background: var(--light-background);\n$light-background2: var(--light-background2);\n$light-background3: var(--light-background3);\n$color: var(--color);\n$light-color: var(--light-color);\n$light-color2: var(--light-color2);\n$light-color3: var(--light-color3);\n$rgb-color: var(--rgb-color);\n$table-border-color: var(--table-border-color);\n$table-alternate-color: var(--table-alternate-color);\n$link-color: var(--link-color);\n$framing-color: var(--framing-color);\n$rgb-framing-color: var(--rgb-framing);\n$card-border: var(--card-border);\n$input-border-color: var(--input-border);\n\n$secondary-color: var(--secondary-color);\n$tertiary-color: var(--tertiary-color);\n\n$table-selected: var(--table-selected);\n$table-drop: var(--table-drop);\n\n$arrow-blue: #3b88c3;\n\n$mobile-size: 650px;\n$extra-mobile-size: 399px;\n\n@mixin dark-mode-transition {\n  transition: background-color 0.3s ease, color 0.3s ease;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"goBack": "DSdtRngiFy3ASoYzXxw9",
	"message": "WvdxOWhy7hRKOrDNNbOt"
};
export default ___CSS_LOADER_EXPORT___;
