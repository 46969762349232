// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UChTT6Agtro18DZS5qlH{margin:25px 0;display:flex;flex-direction:column;gap:5px}.eXUSP5tN4fozbgg4Yyw8{display:flex;flex-direction:row;gap:5px;position:relative}.jHpURrih9ZXX_Rp1Xwzh{width:60px;border-bottom:2px solid var(--light-color);text-align:center;font-size:.8em;display:flex;justify-content:center;align-items:flex-end;line-height:1.5em;padding-bottom:5px}@media(max-width: 650px){.jHpURrih9ZXX_Rp1Xwzh{width:50px;font-size:.6em}}", "",{"version":3,"sources":["webpack://./src/styles/ResultGrid.module.scss"],"names":[],"mappings":"AAEA,sBACE,aAAA,CACA,YAAA,CACA,qBAAA,CACA,OAAA,CAIF,sBACE,YAAA,CACA,kBAAA,CACA,OAAA,CACA,iBAAA,CAGF,sBAKE,UAAA,CACA,0CAAA,CACA,iBAAA,CACA,cAAA,CACA,YAAA,CACA,sBAAA,CACA,oBAAA,CACA,iBAAA,CACA,kBAAA,CAZA,yBADF,sBAEI,UAAA,CACA,cAAA,CAAA","sourcesContent":["@import \"variables\";\n\n.tableContainer {\n  margin: 25px 0;\n  display: flex;\n  flex-direction: column;\n  gap: 5px;\n\n}\n\n.rowContainer {\n  display: flex;\n  flex-direction: row;\n  gap: 5px;\n  position: relative;\n}\n\n.headerCell {\n  @media (max-width: $mobile-size) {\n    width: 50px;\n    font-size: .6em;\n  }\n  width: 60px;\n  border-bottom: 2px solid $light-color;\n  text-align: center;\n  font-size: .8em;\n  display: flex;\n  justify-content: center;\n  align-items: flex-end;\n  line-height: 1.5em;\n  padding-bottom: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableContainer": "UChTT6Agtro18DZS5qlH",
	"rowContainer": "eXUSP5tN4fozbgg4Yyw8",
	"headerCell": "jHpURrih9ZXX_Rp1Xwzh"
};
export default ___CSS_LOADER_EXPORT___;
