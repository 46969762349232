export var DEFAULT_CARD = {
    tcgplayer_id: 0,
    name: '',
    id: '',
    color_identity: [],
    type_line: '',
    cmc: 0,
    power: '',
    toughness: '',
    released_at: '',
    set: '',
    rarity: '',
    keywords: [],
    image_uris: {
        small: '',
        normal: '',
        art_crop: '',
    },
};
