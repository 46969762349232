import React, { useState } from "react";
import { useKeyPressEvent } from "react-use";
import { Icon } from "semantic-ui-react";
import Button from "../Semantic/Button";
import { SimpleCard } from "./Card";
import styles from "../../styles/CardList.module.scss";
var CardList = function (_a) {
    var cardMap = _a.cardMap;
    var _b = useState(false), open = _b[0], setOpen = _b[1];
    useKeyPressEvent("Escape", function () { return setOpen(false); });
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { onClick: function () { return setOpen(true); } }, "View Cards"),
        React.createElement("div", { className: styles.listContainer, style: { display: open ? "grid" : "none" } },
            React.createElement("button", { className: styles.closeButton, onClick: function () { return setOpen(false); } },
                React.createElement(Icon, { name: "close" })),
            Object.keys(cardMap).map(function (name) { return (React.createElement(SimpleCard, { uid: cardMap[name], key: name })); }))));
};
export default CardList;
