import React from "react";
import { Card } from "./CardList/Card";
import Button from "./Semantic/Button";
import Modal from "./Semantic/Modal";
var CardModal = function (_a) {
    var card = _a.card, clearCard = _a.clearCard, guessCard = _a.guessCard;
    if (!card)
        return null;
    return (React.createElement(Modal, { onClose: clearCard, open: !!card, closeIcon: true, size: "mini" },
        React.createElement(Modal.Header, null, card.name),
        React.createElement(Modal.Content, { style: { display: "flex", justifyContent: "center" } },
            React.createElement(Card, { card: card })),
        React.createElement(Modal.Actions, null,
            React.createElement(Button, { onClick: clearCard }, "Cancel"),
            React.createElement(Button, { content: "Guess this card", labelPosition: "right", icon: "checkmark", onClick: guessCard, positive: true }))));
};
export default CardModal;
