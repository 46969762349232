import React from "react";
import { CardModal } from "./CardList/Card";
import Button from "./Semantic/Button";
import { logAppEvent } from "../services/firebase.service";
var AnswerReveal = function (_a) {
    var answerCard = _a.answerCard;
    if (!answerCard)
        return null;
    var trigger = React.createElement(Button, { onClick: function () { return logAppEvent('give_up_button'); } }, "I give up");
    return React.createElement(CardModal, { card: answerCard, trigger: trigger });
};
export default AnswerReveal;
