// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".minzWSow9jMjVgyGms8c{height:50px;width:70px;position:absolute;left:-95px;top:5px;cursor:pointer;background:transparent;border:none;padding:0}@media(max-width: 650px){.minzWSow9jMjVgyGms8c{width:45px;height:35px;left:-60px;top:10px}}@media(max-width: 399px){.minzWSow9jMjVgyGms8c{width:20px;height:35px;left:-30px;top:10px}}.jHNA7nCmgbwu4Py5C37y{width:100%}", "",{"version":3,"sources":["webpack://./src/styles/GuessCell.module.scss"],"names":[],"mappings":"AAEA,sBAaE,WAAA,CACA,UAAA,CAEA,iBAAA,CAEA,UAAA,CACA,OAAA,CAEA,cAAA,CAEA,sBAAA,CACA,WAAA,CACA,SAAA,CAxBA,yBADF,sBAEI,UAAA,CACA,WAAA,CACA,UAAA,CACA,QAAA,CAAA,CAEF,yBAPF,sBAQI,UAAA,CACA,WAAA,CACA,UAAA,CACA,QAAA,CAAA,CAiBJ,sBACE,UAAA","sourcesContent":["@import \"variables\";\n\n.guessCell {\n  @media (max-width: $mobile-size) {\n    width: 45px;\n    height: 35px;\n    left: -60px;\n    top: 10px;\n  }\n  @media (max-width: $extra-mobile-size) {\n    width: 20px;\n    height: 35px;\n    left: -30px;\n    top: 10px;\n  }\n  height: 50px;\n  width: 70px;\n\n  position: absolute;\n\n  left: -95px;\n  top: 5px;\n\n  cursor: pointer;\n\n  background: transparent;\n  border: none;\n  padding: 0;\n}\n\n.image {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"guessCell": "minzWSow9jMjVgyGms8c",
	"image": "jHNA7nCmgbwu4Py5C37y"
};
export default ___CSS_LOADER_EXPORT___;
