var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from "react";
import Modal from "./Semantic/Modal";
import Button from "./Semantic/Button";
import styles from '../styles/InstructionModal.module.scss';
import { DEFAULT_CARD } from "../models/card";
import Headers from "./ResultGrid/Headers";
import Row from "./ResultGrid/Row";
import { useLocalStorage } from "react-use";
var KAALIA = __assign(__assign({}, DEFAULT_CARD), { name: 'Kaalia of the Vast', released_at: '2011-06-17', set: 'cmd', type_line: 'Legendary Creature — Human Cleric', color_identity: ['B', 'R', 'W'], cmc: 4, power: '2', toughness: '2', rarity: 'mythic', "image_uris": {
        "small": "https://c1.scryfall.com/file/scryfall-cards/small/front/4/b/4b71d89b-7ba4-406f-8736-ac62b9864f21.jpg?1592714121",
        "normal": "https://c1.scryfall.com/file/scryfall-cards/normal/front/4/b/4b71d89b-7ba4-406f-8736-ac62b9864f21.jpg?1592714121",
        "art_crop": "https://c1.scryfall.com/file/scryfall-cards/art_crop/front/4/b/4b71d89b-7ba4-406f-8736-ac62b9864f21.jpg?1592714121",
    } });
var MARCHESA = __assign(__assign({}, DEFAULT_CARD), { name: 'Queen Marchesa', released_at: '2016-08-26', set: 'cn2', type_line: 'Legendary Creature — Human Assassin', color_identity: ['B', 'R', 'W'], cmc: 4, power: '3', toughness: '3', rarity: 'mythic', "image_uris": {
        "small": "https://c1.scryfall.com/file/scryfall-cards/small/front/0/3/03f4341c-088b-4f35-b82b-3d98d8a93de4.jpg?1576382166",
        "normal": "https://c1.scryfall.com/file/scryfall-cards/normal/front/0/3/03f4341c-088b-4f35-b82b-3d98d8a93de4.jpg?1576382166",
        "art_crop": "https://c1.scryfall.com/file/scryfall-cards/art_crop/front/0/3/03f4341c-088b-4f35-b82b-3d98d8a93de4.jpg?1576382166",
    } });
var OMNATH = __assign(__assign({}, DEFAULT_CARD), { name: 'Omnath, Locus of Creation', released_at: '2020-09-25', set: 'znr', type_line: 'Legendary Creature — Elemental', color_identity: ["G", "R", "U", "W"], cmc: 4, power: '4', toughness: '4', rarity: 'mythic', "image_uris": {
        "small": "https://c1.scryfall.com/file/scryfall-cards/small/front/4/e/4e4fb50c-a81f-44d3-93c5-fa9a0b37f617.jpg?1639436752",
        "normal": "https://c1.scryfall.com/file/scryfall-cards/normal/front/4/e/4e4fb50c-a81f-44d3-93c5-fa9a0b37f617.jpg?1639436752",
        "art_crop": "https://c1.scryfall.com/file/scryfall-cards/art_crop/front/4/e/4e4fb50c-a81f-44d3-93c5-fa9a0b37f617.jpg?1639436752",
    } });
var SELVALA = __assign(__assign({}, DEFAULT_CARD), { name: 'Selvala, Heart of the Wilds', released_at: '2016-08-26', set: 'cn2', type_line: 'Legendary Creature — Elf Scout', color_identity: ["G"], cmc: 3, power: '2', toughness: '3', rarity: 'mythic', "image_uris": {
        "small": "https://c1.scryfall.com/file/scryfall-cards/small/front/9/9/99a3e619-fbdb-406c-9f21-eb582cb878c0.jpg?1576382100",
        "normal": "https://c1.scryfall.com/file/scryfall-cards/normal/front/9/9/99a3e619-fbdb-406c-9f21-eb582cb878c0.jpg?1576382100",
        "art_crop": "https://c1.scryfall.com/file/scryfall-cards/art_crop/front/9/9/99a3e619-fbdb-406c-9f21-eb582cb878c0.jpg?1576382100",
    } });
var InstructionsModal = function () {
    var _a = useState(false), open = _a[0], setOpen = _a[1];
    var _b = useLocalStorage('hasLoaded', false), hasLoaded = _b[0], setHasLoaded = _b[1];
    var storedGuesses = useLocalStorage('storedGuesses', [])[0];
    useEffect(function () {
        if ((storedGuesses === null || storedGuesses === void 0 ? void 0 : storedGuesses.length) && !hasLoaded)
            setHasLoaded(true);
        else if (!hasLoaded) {
            setTimeout(function () { return setOpen(true); }, 1000);
            setHasLoaded(true);
        }
    }, []);
    return (React.createElement(Modal, { open: open, onClose: function () { return setOpen(false); }, onOpen: function () { return setOpen(true); }, size: "small", closeIcon: true, trigger: React.createElement(Button, { title: "How to play", icon: "question circle outline" }) },
        React.createElement(Modal.Header, null, "How to Play"),
        React.createElement(Modal.Content, null,
            React.createElement("p", null, "Guess the Commander in 6 tries."),
            React.createElement("p", null, "Each guess must be a Commander from the top 500 Commanders on EDHREC."),
            React.createElement("p", { className: styles.divider }, "After each guess, the color of the tiles will change to show how close your guess was to the Commander. In general, green designates the property is an exact match, yellow indicates partial match, arrows indicates the answer property is above or below your guess, and gray indicates there is no overlap between your guess and the property."),
            React.createElement("h4", null, "Examples"),
            React.createElement("p", { className: styles.divider }, "Consider the correct answer is Queen Marchesa"),
            React.createElement("p", null, "If a guess is Kaalia of the Vast:"),
            React.createElement("div", { className: styles.centerRow },
                React.createElement(Headers, null),
                React.createElement(Row, { guesses: [KAALIA], answerCard: MARCHESA, row: 0 })),
            React.createElement("p", { className: styles.divider }, "The color identity column shows green because the color is an exact match. The Mana Value (CMC) column is green because they also match exactly. The Release column shows blue and an up arrow because Marchesa was released after Kaalia. The Power+Toughness column shows blue and an up arrow because the sum of Kaalia's P/T is less than Marchesa's. Finally, the type column shows yellow because they share a type, Human, but do not match other types."),
            React.createElement("p", null, "If a guess is Omnath, Locus of Creation:"),
            React.createElement("div", { className: styles.centerRow },
                React.createElement(Headers, null),
                React.createElement(Row, { guesses: [OMNATH], answerCard: MARCHESA, row: 0 })),
            React.createElement("p", { className: styles.divider }, "The color identity column now shows yellow because colors overlap but it is not an exact match. The type column is now gray as there are no shared types."),
            React.createElement("p", null, "If a guess is Selvala, Heart of the Wilds:"),
            React.createElement("div", { className: styles.centerRow },
                React.createElement(Headers, null),
                React.createElement(Row, { guesses: [SELVALA], answerCard: MARCHESA, row: 0 })),
            React.createElement("p", { className: styles.divider }, "Here, the color identity is gray as green has no overlap. The CMC for the guess was too low so an up arrow is displayed. The editions match so the Release column shows green. Power+Toughness for the guess is too low so an up arrow is displayed. No types match so gray."))));
};
export default InstructionsModal;
