// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".scwDX34Ljw23vnoytNBQ{display:flex;flex-direction:column;align-items:center;margin:15px 0}.rnSN02dNjpe9T5yCcn1B{display:flex;justify-content:space-between}", "",{"version":3,"sources":["webpack://./src/styles/DailyStats.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,aAAA,CAGF,sBACE,YAAA,CACA,6BAAA","sourcesContent":[".dailyStatsOuter {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin: 15px 0;\n}\n\n.statDiv {\n  display: flex;\n  justify-content: space-between;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dailyStatsOuter": "scwDX34Ljw23vnoytNBQ",
	"statDiv": "rnSN02dNjpe9T5yCcn1B"
};
export default ___CSS_LOADER_EXPORT___;
