var ColumnStyles = {
    green: 'green',
    yellow: 'yellow',
    down: 'down',
    up: 'up',
    empty: 'empty',
    gray: 'gray'
};
export function checkIdentity(answer, guess) {
    var intersection = answer.color_identity.filter(function (color) { return guess.color_identity.includes(color); });
    if (intersection.length === guess.color_identity.length && intersection.length === answer.color_identity.length)
        return ColumnStyles.green;
    else if (intersection.length)
        return ColumnStyles.yellow;
    return ColumnStyles.gray;
}
export function checkManaValue(answer, guess) {
    if (guess.cmc > answer.cmc)
        return ColumnStyles.down;
    else if (guess.cmc < answer.cmc)
        return ColumnStyles.up;
    return ColumnStyles.green;
}
export function checkInitialPrinting(answer, guess) {
    if (guess.released_at > answer.released_at)
        return ColumnStyles.down;
    else if (guess.released_at < answer.released_at)
        return ColumnStyles.up;
    return ColumnStyles.green;
}
export function checkPowerToughnessSum(answer, guess) {
    var guessPower = Number(guess.card_faces ? guess.card_faces[0].power : guess.power) || 0;
    var guessToughness = Number(guess.card_faces ? guess.card_faces[0].toughness : guess.toughness) || 0;
    var answerPower = Number(answer.card_faces ? answer.card_faces[0].power : answer.power) || 0;
    var answerToughness = Number(answer.card_faces ? answer.card_faces[0].toughness : answer.toughness) || 0;
    if (guessPower + guessToughness > answerPower + answerToughness)
        return ColumnStyles.down;
    else if (guessPower + guessToughness < answerPower + answerToughness)
        return ColumnStyles.up;
    return ColumnStyles.green;
}
export function checkSubTypes(answer, guess) {
    var guessSubtypes = guess.type_line.split('—')[1].trim().split(' ');
    var answerSubtypes = answer.type_line.split('—')[1].trim().split(' ');
    var intersection = answerSubtypes.filter(function (type) { return guessSubtypes.includes(type); });
    if (intersection.length === guessSubtypes.length && intersection.length === answerSubtypes.length)
        return ColumnStyles.green;
    else if (intersection.length)
        return ColumnStyles.yellow;
    return ColumnStyles.gray;
}
export function checkKeywords(answer, guess) {
    var intersection = answer.keywords.filter(function (type) { return guess.keywords.includes(type); });
    if (intersection.length === guess.keywords.length && intersection.length === answer.keywords.length)
        return ColumnStyles.green;
    else if (intersection.length)
        return ColumnStyles.yellow;
    return ColumnStyles.gray;
}
export function checkPower(answer, guess) {
    var guessPower = Number(guess.card_faces ? guess.card_faces[0].power : guess.power) || 0;
    var answerPower = Number(answer.card_faces ? answer.card_faces[0].power : answer.power) || 0;
    if (guessPower > answerPower)
        return ColumnStyles.down;
    else if (guessPower < answerPower)
        return ColumnStyles.up;
    return ColumnStyles.green;
}
export function checkToughness(answer, guess) {
    var guessToughness = Number(guess.card_faces ? guess.card_faces[0].toughness : guess.toughness) || 0;
    var answerToughness = Number(answer.card_faces ? answer.card_faces[0].toughness : answer.toughness) || 0;
    if (guessToughness > answerToughness)
        return ColumnStyles.down;
    else if (guessToughness < answerToughness)
        return ColumnStyles.up;
    return ColumnStyles.green;
}
export function checkRowCorrectness(answer, guess) {
    var column0 = ColumnStyles.green === checkIdentity(answer, guess);
    var column1 = ColumnStyles.green === checkManaValue(answer, guess);
    var column2 = ColumnStyles.green === checkInitialPrinting(answer, guess);
    var column3 = ColumnStyles.green === checkPowerToughnessSum(answer, guess);
    var column4 = ColumnStyles.green === checkSubTypes(answer, guess);
    return [column0, column1, column2, column3, column4];
}
export function checkGameCorrectness(answer, guesses) {
    return guesses.map(function (guess) { return checkRowCorrectness(answer, guess); });
}
export function guessToEmoji(answer, guess) {
    var CORRECT_SQUARE = "🟩";
    var INCORRECT_SQUARE = "⬛";
    var PARTIAL_SQUARE = '🟨';
    var UP_ARROW = '⬆️';
    var DOWN_ARROW = '⬇️';
    var identity = checkIdentity(answer, guess);
    var manaValue = checkManaValue(answer, guess);
    var initialPrinting = checkInitialPrinting(answer, guess);
    var powerToughnessSum = checkPowerToughnessSum(answer, guess);
    var subTypes = checkSubTypes(answer, guess);
    var emoji = '';
    if (identity === ColumnStyles.green)
        emoji += CORRECT_SQUARE;
    else if (identity === ColumnStyles.yellow)
        emoji += PARTIAL_SQUARE;
    else
        emoji += INCORRECT_SQUARE;
    if (manaValue === ColumnStyles.green)
        emoji += CORRECT_SQUARE;
    else if (manaValue === ColumnStyles.up)
        emoji += UP_ARROW;
    else if (manaValue === ColumnStyles.down)
        emoji += DOWN_ARROW;
    else
        emoji += INCORRECT_SQUARE;
    if (initialPrinting === ColumnStyles.green)
        emoji += CORRECT_SQUARE;
    else if (initialPrinting === ColumnStyles.up)
        emoji += UP_ARROW;
    else if (initialPrinting === ColumnStyles.down)
        emoji += DOWN_ARROW;
    else
        emoji += INCORRECT_SQUARE;
    if (powerToughnessSum === ColumnStyles.green)
        emoji += CORRECT_SQUARE;
    else if (powerToughnessSum === ColumnStyles.up)
        emoji += UP_ARROW;
    else if (powerToughnessSum === ColumnStyles.down)
        emoji += DOWN_ARROW;
    else
        emoji += INCORRECT_SQUARE;
    if (subTypes === ColumnStyles.green)
        emoji += CORRECT_SQUARE;
    else if (subTypes === ColumnStyles.yellow)
        emoji += PARTIAL_SQUARE;
    else
        emoji += INCORRECT_SQUARE;
    return emoji;
}
export function gameStateToEmoji(answer, guesses) {
    return guesses.map(function (guess) { return guessToEmoji(answer, guess); }).join('\n');
}
