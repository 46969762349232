import React, { useState } from "react";
import Modal from "./Semantic/Modal";
import Button from "./Semantic/Button";
import ToggleDarkMode from "./ToggleDarkMode";
import ColorAssistToggle from "./ColorAssistToggle";
import styles from "../styles/SettingsModal.module.scss";
var SettingsModal = function () {
    var _a = useState(false), open = _a[0], setOpen = _a[1];
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { onClick: function () { return setOpen(true); }, icon: "settings" }),
        React.createElement(Modal, { open: open, onClose: function () { return setOpen(false); }, size: "mini", closeIcon: true },
            React.createElement(Modal.Header, null, "Settings"),
            React.createElement(Modal.Content, { className: styles.modalContent },
                React.createElement(ToggleDarkMode, null),
                React.createElement(ColorAssistToggle, null)))));
};
export default SettingsModal;
