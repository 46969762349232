// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
var firebaseConfig = {
    apiKey: "AIzaSyA2cV5NvkuBDX3XnmzrOkG9LEz7RCS9PCY",
    authDomain: "legend-tutor.firebaseapp.com",
    projectId: "legend-tutor",
    storageBucket: "legend-tutor.appspot.com",
    messagingSenderId: "24800918828",
    appId: "1:24800918828:web:ba38f8eadc348d705ac089",
    measurementId: "G-41GQMHHGKF",
};
// Initialize Firebase
var app = initializeApp(firebaseConfig);
export var analytics = getAnalytics(app);
export var logAppEvent = function (eventName, eventParams, options) { return logEvent(analytics, eventName, eventParams, options); };
