// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TueyraeZ7kVaQhNKlB_t{display:flex;flex-direction:column;align-items:center;text-align:center}.kDky7nuY0E3ZyO9va8MQ{margin-bottom:1rem}.HrIWa6PWXcGSjCPXjQmg{display:flex;gap:10px;margin-bottom:.5rem}.HrIWa6PWXcGSjCPXjQmg>*{width:100%}.eJdHUJiHqZhng5H0LLRr{width:100%;margin:0 !important;margin-bottom:.5rem !important}", "",{"version":3,"sources":["webpack://./src/styles/WinModal.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,iBAAA,CAGF,sBACE,kBAAA,CAGF,sBACE,YAAA,CACA,QAAA,CAMA,mBAAA,CAJA,wBACE,UAAA,CAMJ,sBACE,UAAA,CAEA,mBAAA,CACA,8BAAA","sourcesContent":[".winContent {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  text-align: center;\n}\n\n.spacer {\n  margin-bottom: 1rem;\n}\n\n.shareables {\n  display: flex;\n  gap: 10px;\n\n  > * {\n    width: 100%;\n  }\n\n  margin-bottom: 0.5rem;\n}\n\n.infinitButton {\n  width: 100%;\n\n  margin: 0 !important;\n  margin-bottom: 0.5rem !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"winContent": "TueyraeZ7kVaQhNKlB_t",
	"spacer": "kDky7nuY0E3ZyO9va8MQ",
	"shareables": "HrIWa6PWXcGSjCPXjQmg",
	"infinitButton": "eJdHUJiHqZhng5H0LLRr"
};
export default ___CSS_LOADER_EXPORT___;
