import React, { useEffect } from "react";
import { useCookies } from "react-cookie";
import Checkbox from "./Semantic/Checkbox";
import { CookieKeys, Expires } from "../constants/cookies";
import { setDarkModeCss, setLightModeCss } from "../services/theme.service";
var DarkModeToggle = function () {
    var _a = useCookies([CookieKeys.darkMode]), cookies = _a[0], setCookies = _a[1];
    var toggleDarkMode = function () {
        var newValue = cookies[CookieKeys.darkMode] === "true" ? "false" : "true";
        setCookies(CookieKeys.darkMode, newValue, { maxAge: Expires.YEAR });
    };
    useEffect(function () {
        if (!cookies.darkMode || cookies.darkMode === "false")
            setLightModeCss();
        else
            setDarkModeCss();
    }, [cookies.darkMode]);
    return (React.createElement(Checkbox, { toggle: true, label: "Enable Dark Mode", checked: cookies.darkMode === "true", onClick: toggleDarkMode }));
};
export default DarkModeToggle;
