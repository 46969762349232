import React from 'react';
import styles from '../../styles/Bar.module.scss';
var Bar = function (_a) {
    var winNumber = _a.winNumber, count = _a.count, max = _a.max, mostRecentWinNumber = _a.mostRecentWinNumber;
    var backgroundColor = '#86a579';
    var active = "".concat(mostRecentWinNumber) === winNumber;
    if (!max)
        max = 1;
    return (React.createElement("div", { className: styles.barOuter },
        winNumber,
        React.createElement("div", { className: styles.secondaryContainer },
            React.createElement("div", { className: styles.growingBar, style: active ? { backgroundColor: backgroundColor, flexGrow: count / max } : { flexGrow: count / max } }, count),
            React.createElement("div", { style: { flexGrow: 1 - count / max } }))));
};
export default Bar;
