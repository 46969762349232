// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".a9mhzhPlWIbecIE58hnm{padding-bottom:20px;border-bottom:1px solid var(--light-color2)}.dXPRI1As49T4qwbwRBbW{display:flex;flex-direction:column;align-items:center;gap:5px}", "",{"version":3,"sources":["webpack://./src/styles/InstructionModal.module.scss"],"names":[],"mappings":"AAEA,sBACE,mBAAA,CACA,2CAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,OAAA","sourcesContent":["@import \"variables\";\n\n.divider {\n  padding-bottom: 20px;\n  border-bottom: 1px solid $light-color2;\n}\n\n.centerRow {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"divider": "a9mhzhPlWIbecIE58hnm",
	"centerRow": "dXPRI1As49T4qwbwRBbW"
};
export default ___CSS_LOADER_EXPORT___;
