import React from "react";
import { useLocalStorage } from "react-use";
import Statistic from "../Semantic/Statistic";
import styles from '../../styles/DailyStats.module.scss';
import Bar from "./Bar";
var calculateStreaks = function (list) {
    var result = {
        currentStreak: 0,
        maxStreak: 0,
        distribution: {
            "1": 0,
            "2": 0,
            "3": 0,
            "4": 0,
            "5": 0,
            "6": 0,
        },
        mostRecentWinNumber: 0,
    };
    var isCurrent = true;
    var streak = 0;
    for (var _i = 0, list_1 = list; _i < list_1.length; _i++) {
        var _a = list_1[_i], _codexNumberStr = _a[0], winNumber = _a[1];
        if (isCurrent && winNumber)
            result.currentStreak += 1;
        else
            isCurrent = false;
        if (winNumber) {
            streak += 1;
            result.distribution["".concat(winNumber)] += 1;
            if (!result.mostRecentWinNumber)
                result.mostRecentWinNumber = winNumber;
        }
        else {
            if (streak > result.maxStreak)
                result.maxStreak = streak;
            streak = 0;
        }
    }
    if (streak > result.maxStreak)
        result.maxStreak = streak;
    return result;
};
var DailyStats = function (_a) {
    var storedWinMap = useLocalStorage('storedWinMap', {})[0];
    if (!storedWinMap)
        storedWinMap = {};
    var sortedWins = Object.entries(storedWinMap).sort(function (_a, _b) {
        var numStringA = _a[0];
        var numStringB = _b[0];
        return (Number(numStringA) > Number(numStringB) ? -1 : 1);
    });
    var _b = calculateStreaks(sortedWins), currentStreak = _b.currentStreak, maxStreak = _b.maxStreak, distribution = _b.distribution, mostRecentWinNumber = _b.mostRecentWinNumber;
    var numberPlayed = sortedWins.length;
    var winPercentage = Math.round(Object.values(storedWinMap).filter(function (isWin) { return isWin; }).length / numberPlayed * 100);
    var maxDistribution = Object.values(distribution).sort().reverse()[0];
    return (React.createElement("div", { className: styles.dailyStatsOuter },
        React.createElement("h4", null, "Daily Mode Stats"),
        React.createElement("div", { className: styles.statDiv },
            React.createElement(Statistic, { size: "tiny", label: "Played", value: numberPlayed }),
            React.createElement(Statistic, { size: "tiny", label: "Win %", value: winPercentage }),
            React.createElement(Statistic, { size: "tiny", label: "Current Streak", value: currentStreak }),
            React.createElement(Statistic, { size: "tiny", label: "Max Streak", value: maxStreak })),
        React.createElement("h4", null, "Guess Distribution"),
        Object.entries(distribution).map(function (_a) {
            var winNumber = _a[0], count = _a[1];
            return (React.createElement(Bar, { winNumber: winNumber, count: count, max: maxDistribution, mostRecentWinNumber: mostRecentWinNumber, key: winNumber }));
        })));
};
export default DailyStats;
