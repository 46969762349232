import React, { useEffect, useState } from 'react';
import { useCookies } from "react-cookie";
import { checkIdentity, checkInitialPrinting, checkManaValue, checkPowerToughnessSum, checkSubTypes } from '../../services/gameLogic.service';
import { CookieKeys } from "../../constants/cookies";
import SetSymbol from "../SetSymbol";
import styles from '../../styles/Cell.module.scss';
var MONTHS = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
var Cell = function (_a) {
    var col = _a.col, guessCard = _a.guessCard, answerCard = _a.answerCard;
    var _b = useState('empty'), delayedStyleProp = _b[0], setDelayedStyleProp = _b[1];
    var cookies = useCookies([CookieKeys.colorblindAssist])[0];
    var useColorAssist = cookies[CookieKeys.colorblindAssist] === 'true';
    var styleProps = 'empty';
    var value = '';
    if (guessCard && answerCard) {
        styleProps = 'gray';
        // Color Check
        if (col === 0) {
            styleProps = checkIdentity(answerCard, guessCard);
            value = (React.createElement("div", { className: styles.colorIconContainer }, guessCard.color_identity.map(function (color) { return React.createElement("i", { key: color, className: "ms ms-cost ms-".concat(color.toLowerCase()) }); })));
        }
        // Mana Check
        else if (col === 1) {
            styleProps = checkManaValue(answerCard, guessCard);
            value = guessCard.cmc;
        }
        // Set Check
        else if (col === 2) {
            styleProps = checkInitialPrinting(answerCard, guessCard);
            var year = guessCard.released_at.split('-')[0];
            var month = MONTHS[Number(guessCard.released_at.split('-')[1]) - 1];
            value = (React.createElement("span", { className: styles.editionContainer },
                React.createElement(SetSymbol, { set: guessCard.set, rarity: guessCard.rarity }),
                " ",
                month,
                React.createElement("br", null),
                year));
        }
        // Power Check
        else if (col === 3) {
            var guessPower = Number(guessCard.card_faces ? guessCard.card_faces[0].power : guessCard.power) || 0;
            var guessToughness = Number(guessCard.card_faces ? guessCard.card_faces[0].toughness : guessCard.toughness) || 0;
            styleProps = checkPowerToughnessSum(answerCard, guessCard);
            value = guessPower + guessToughness;
        }
        // Subtypes Check
        else if (col === 4) {
            var guessSubtypes = guessCard.type_line.split('—')[1].trim().split(' ');
            styleProps = checkSubTypes(answerCard, guessCard);
            value = guessSubtypes.join(', ');
        }
    }
    useEffect(function () {
        if (styleProps === 'empty')
            setDelayedStyleProp(styleProps);
        else
            setTimeout(function () { return setDelayedStyleProp(styleProps); }, col * 500);
    }, [styleProps, col]);
    return (React.createElement("div", { className: styleProps === 'up' || styleProps === 'down' ? "".concat(styles.cellOuter, " ").concat(styles.blue) : styles.cellOuter },
        React.createElement("div", { className: styles.cellInner, style: delayedStyleProp !== 'empty' ? { transform: 'rotateY(180deg)' } : {} },
            React.createElement("div", { className: styles.cell }),
            React.createElement("div", { className: "".concat(styles[delayedStyleProp], " ").concat(useColorAssist ? styles.colorAssist : '') }, delayedStyleProp !== 'empty' && value))));
};
export default Cell;
