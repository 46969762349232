import React from "react";
import { useCookies } from "react-cookie";
import { CookieKeys, Expires } from "../constants/cookies";
import Checkbox from "./Semantic/Checkbox";
var ColorAssistToggle = function () {
    var _a = useCookies([CookieKeys.colorblindAssist]), cookies = _a[0], setCookies = _a[1];
    var handleToggleColorAssist = function () {
        var newValue = cookies[CookieKeys.colorblindAssist] === "true" ? "false" : "true";
        setCookies(CookieKeys.colorblindAssist, newValue, { maxAge: Expires.YEAR });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Checkbox, { toggle: true, label: "Color Assistance", onClick: handleToggleColorAssist, checked: cookies[CookieKeys.colorblindAssist] === "true" }),
        React.createElement("p", null,
            "Enabling this mode adds visual indicators to answer squares to help distinguish correctness.",
            React.createElement("ul", null,
                React.createElement("li", null, "\uD83D\uDEAB - Completely incorrect"),
                React.createElement("li", null, "\uD83E\uDD14 - Partially correct"),
                React.createElement("li", null, "\u2728 - Completely correct")))));
};
export default ColorAssistToggle;
